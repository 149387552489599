<template>

    <div class="wrap scroll p-mypage">

        <layout-header title="자녀 설정" btn-left="logo" btn-right="close"></layout-header>

        <div class="container">
            <div class="content">
                <div class="child-section">
                    <div class="inner">
                        <div class="child-profile">
                            <img alt="" :src="coAccountVO.profileSrc">
                            <button class="btn-change modal-open" data-pop="changeCharacter" type="button"><span
                                class="blind">캐릭터 변경</span></button>
                        </div>
                        <div class="child-info">
                            <dl>
                                <dt><i class="ico-name"></i><span>자녀 이름</span></dt>
                                <dd>{{ coAccountVO.nickName }}({{ coAccountVO.gender === 'm' ? '남' : coAccountVO.gender === 'f' ? '여' : '' }})</dd>
                            </dl>
                            <dl>
                                <dt><i class="ico-birth"></i><span>출생연도</span></dt>
                                <dd>{{ coAccountVO.birthday }}</dd>
                            </dl>
                            <dl>
                                <dt><i class="ico-id"></i><span>아이디</span></dt>
                                <dd>{{ coAccountVO.coEmailId }}</dd>
                            </dl>
                            <dl>
                                <dt><i class="ico-join"></i><span>가입일</span></dt>
                                <dd>{{ createDate }}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
                <div class="learn-section tabs">
                    <!-- tab -->
                    <ul class="tab-nav">
                        <template v-for="(v, k) in titleList">
                            <li :class="k == defaultSelected ? 'active' : ''" @click="getAnsRecord(Object.keys(levelList).find(key => levelList[key] === v))" :value="k">
                                <button type="button">{{ v }}</button>
                            </li>
                        </template>
                    </ul>
                    <template v-for="(v, k) in levelList">
                        <div class="tab-cont" :class="k == defaultSelected ? 'active' : ''">
                            <ul class="learn-count">
                                <li>
                                    <strong class="tit">출석횟수</strong>
                                    <span class="desc">총 <b>{{ totalAttendance }}</b>일 출석</span>
                                    <router-link class="btn-more" :to="{name: 'attendance'}"><span class="blind">더보기</span></router-link>
                                </li>
                                <li>
                                    <strong class="tit">학습현황</strong>
                                    <span class="desc"><b>{{ answerRecord }}</b>회 시청</span>
                                    <router-link class="btn-more" :to="{name: 'myEducation'}"><span class="blind">더보기</span></router-link>
                                </li>
                            </ul>
                            <div class="learn-attend">
                                <strong>칭찬스티커</strong>
                                <ul class="attend-list">
                                    <template v-for="(v, k) in stickerRecord">
                                        <li>
                                            <span class="tit"><i></i>{{ Object.keys(v)[0] }}</span>
                                            <span class="num">{{ Object.values(v)[0] }}</span>
                                        </li>
                                    </template>
                                </ul>
                                <router-link class="btn-more" :to="{name: 'mySticker'}"><span class="blind">더보기</span></router-link>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div id="changeCharacter" class="modal modal-common m-character">
            <div class="modal-content">
                <h3>캐릭터 변경</h3>
                <form action="javascript:;">
                    <ul class="character-list">
                        <template v-for="(item, index) in profileImageArray">
                            <li>
                                <input :id="'character0' + index" name="character" type="radio">
                                <label :for="'character0' + index"><img :src="item"
                                                                        alt=""></label>
                            </li>
                        </template>
                    </ul>
                    <div class="button-area">
                        <button class="btn-member gray sm modal-close" type="button">취소</button>
                        <button class="btn-member purple sm" @click="choiceImage">변경</button>
                    </div>
                </form>
            </div>
        </div>
        <div id="changePassword" class="modal modal-common m-password">
            <div class="modal-content">
                <h3>비밀번호 변경</h3>
                <form>
                    <fieldset class="join-field">
                        <legend>비밀번호 변경</legend>
                        <div class="form-row">
                            <label for="password">기존 비밀번호</label>
                            <input id="password" class="inp-member" name="" placeholder="숫자, 영문 혼합 6자리 이상"
                                   type="text">
                        </div>
                        <div class="form-row">
                            <label for="password">새 비밀번호</label>
                            <input id="password" class="inp-member" name="" placeholder="숫자, 영문 혼합 6자리 이상"
                                   type="text">
                        </div>
                        <div class="form-row">
                            <label for="password">새 비밀번호 확인</label>
                            <input id="password" class="inp-member" name="" placeholder="숫자, 영문 혼합 6자리 이상"
                                   type="text">
                        </div>
                    </fieldset>
                    <div class="button-area">
                        <button class="btn-member gray sm modal-close" type="button">취소</button>
                        <button class="btn-member purple sm" type="button">변경</button>
                    </div>
                </form>
            </div>
        </div>


    </div>

</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";
import $ from "jquery";

export default {
    name: "profileMyPage",
    components: {LayoutHeader},
    data() {
        const profileImageArray = [
            '/assets/images/common/img_character_01.png',
            '/assets/images/common/img_character_02.png',
            '/assets/images/common/img_character_03.png',
            '/assets/images/common/img_character_04.png',
            '/assets/images/common/img_character_05.png',
            '/assets/images/common/img_character_06.png',
            '/assets/images/common/img_character_07.png',
            '/assets/images/common/img_character_08.png',
        ]
        return {
            profileImageArray,
            coAccountVO: Object,
            createDate: '',
            totalAttendance: '',
            answerRecord: 0,
            stickerRecord: {},
            defaultSelected: '',
            levelList: {},
            titleList: Array
        }
    },
    created() {
        this.requireLoginWithoutAlert()
        this.getProfile()
        this.getTotalAttendance()
        this.getLevelList()
    },
    mounted() {

        $('.modal-open').on('click', function () {
            var attr = $(this).attr('data-pop');
            $('#' + attr).fadeIn();
        })

        $('.modal-close').on('click', function () {
            $('.modal').fadeOut();
        })

    },
    methods: {

        choiceImage() {

            const self = this
            const _ = window._

            _.forEach($('[name="character"]'), (v, i) => {
                if ($(v).is(':checked')) {
                    self.coAccountVO.profileSrc = $('[for="character0' + i + '"]').children().attr('src')
                }
            })

            $('#changeCharacter').fadeOut()

            this.axiosCaller.patch(self, self.APIs.CO_PROFILE + '/', {
                coEmailId: self.coAccountVO.coEmailId,
                profileSrc: self.coAccountVO.profileSrc
            }, (res) => {

                if (res.data.status === 'ok') {
                    self.doLoginProc(self.coAccountVO.coEmailId)
                }

                console.log(res)
            })

        },

        getProfile() {

            this.$eventBus.$emit("startProgress")
            const self = this

            this.axiosCaller.get(self, self.APIs.CO_PROFILE + '/getProfileInfo', {
                coEmailId: this.getLoginId()
            }, (res) => {

                self.coAccountVO = res.data.profileAccount
                self.createDate = (self.coAccountVO.createDate).substring(0, 10)
                self.$eventBus.$emit("doneProgress", 500)

            })
        },

        getTotalAttendance() {
            const self = this

            this.axiosCaller.get(self, self.APIs.CO_RECODE + '/getCountRecord', {
                isDuplicate: false
            }, (res) => {
                if (res.data.status === 'ok') {
                    self.totalAttendance = res.data.count
                }
            })
        },

        getLevelList() {

            const self = this
            const _ = window._

            this.axiosCaller.get(this, this.APIs.EDU_MINE + "/getLastStep", {
                userId: this.getLoginId(),
				cateCode: "200101"
            }, res1 => {

                const result = res1.data
                if (result.status === "ok") {
                    self.defaultSelected = result.eduCourseVO.cidx

                    this.axiosCaller.get(self, this.APIs.EDU_COURSE + "/getOpenCourseList", {
                        openPrevCourse: false,
                        openPrevMain: true,
                        cateCode: "200101",
                        sort: "shortTitle,ASC"
                    }, res2 => {

                        const result = res2.data
                        if (result.status === "ok") {

                            let tmp = []

                            _.forEach(result.list, v => {
                                self.levelList[v.cidx] = v.shortTitle
                                tmp.push(v.shortTitle)
                            })

                            self.titleList = tmp
                            self.levelList = self.levelList
                            self.getAnsRecord(self.defaultSelected)

                            self.$nextTick(() => {

                                $(".tab-nav li").on("click", function () {
                                    var nav = $(this)
                                    var idx = nav.index()
                                    var wrap = nav.closest(".tabs")
                                    wrap.find(".tab-cont").removeClass("active")
                                    wrap.find(".tab-nav li").removeClass("active")
                                    wrap.find(".tab-cont:eq(" + idx + ")").addClass("active")
                                    nav.addClass("active")
                                })

                                $('li[value="' + (self.defaultSelected - 1) + '"]').click()

                            })

                        }
                    })
                }
            })
        },

        getAnsRecord(_key) {

            const _ = window._
            // if (_key * 1 === $('li.active').val() * 1) return

            this.axiosCaller.get(this, this.APIs.EDU_COURSE + "/getRecord", {
                cidx: _key
            }, res1 => {
                this.answerRecord = res1.data.count
            })

            this.axiosCaller.get(this, this.APIs.EDU_COURSE + "/getSticker", {
                cidx: _key
            }, res2 => {

                this.stickerRecord = {}

                _.forEach(res2.data.list, (v, k) => {
                    this.stickerRecord[k] = v
                })

                this.stickerRecord = this.stickerRecord
            })

        }

    },

}
</script>

<style scoped>

</style>